<template>
  <!-- 主体 -->
  <div class="index-page">
    <!-- 头部 -->
    <public-header></public-header>
    <!-- 搜索 -->
    <Body></Body>
    <!-- 主体 -->
    <div class="page_bd">
      <!-- 头部说明 -->
      <div class="index-main-description">
        <div>{{$t('中国台湾地区行业手册')}}</div>
        <div>{{$t('中国台湾地区行业手册的编订原则系参照联合国《国际行业分类标准》（International Standard Industrial Classification of All Economic Activites，简称ISIC）架构，并在其基础上将行业进一步划分为5级，总共囊括19大类、88中类、247小类、517细类、1598子类。分类中每一层级的所有类别都是非此即彼、相互排斥的。行业标准分类的归类原则建立在生产导向的概念上，即以产品、服务及生产要素投入、生产工艺及技术、产出特点及产出的用途等维度进行行业划分。在细类层级的划分上主要考量生产过程中的相似性，即将生产投入、生产程序及生产技术相似者划归为同一类别，有时也会考量产出的特性及用途；在中类、小类的分类层级中，生产程序及生产技术的重要性则相对较低')}}</div>
      </div>
      <!-- 行业说明 -->
      <div class="index-main-industry">{{$t('行业分类')}}</div>
      <!-- 行业标签 -->
      <div class="main-category">
        <div
          class="main-category-item"
          v-for="(item,index) in list"
          :key="index"
          @click="linkCategory(item.value)"
        >
          <img :src="require('@/assets/images/index/'+item.imgUrl)" />
          <div class="main-category-text">{{item.label}}</div>
        </div>
      </div>
      <!-- 回到顶部 -->
      <back-top></back-top>
    </div>
    <public-footer></public-footer>
  </div>
</template>

<script>
//组件引入
import Body from '@/components/body/body.vue'
export default {
  data() {
    return {}
  },
  computed: {
    list() {
      return [
        {
          label: this.$t('农、林、渔、牧业'),
          imgUrl: '1.jpg',
          value: 'A',
        },
        {
          label: this.$t('矿业及土石采取业'),
          imgUrl: '2.jpg',
          value: 'B',
        },
        {
          label: this.$t('制造业'),
          imgUrl: '3.jpg',
          value: 'C',
        },
        {
          label: this.$t('电力及燃气供应业'),
          imgUrl: '4.jpg',
          value: 'D',
        },
        {
          label: this.$t('用水供应及污染整治业'),
          imgUrl: '5.jpg',
          value: 'E',
        },
        {
          label: this.$t('营建工程业'),
          imgUrl: '6.jpg',
          value: 'F',
        },
        {
          label: this.$t('批发及零售业'),
          imgUrl: '7.jpg',
          value: 'G',
        },
        {
          label: this.$t('运输及仓储业'),
          imgUrl: '8.jpg',
          value: 'H',
        },
        {
          label: this.$t('住宿及餐饮业'),
          imgUrl: '9.jpg',
          value: 'I',
        },
        {
          label: this.$t('出版、影音制作、传播及资通信服务业'),
          imgUrl: '10.jpg',
          value: 'J',
        },
        {
          label: this.$t('金融及保险业'),
          imgUrl: '11.jpg',
          value: 'K',
        },
        {
          label: this.$t('不动产业'),
          imgUrl: '19.jpg',
          value: 'L',
        },
        {
          label: this.$t('专业、科学及技术服务业'),
          imgUrl: '12.jpg',
          value: 'M',
        },
        {
          label: this.$t('支持服务业'),
          imgUrl: '13.jpg',
          value: 'N',
        },
        {
          label: this.$t('公共行政及国防&强制性社会安全'),
          imgUrl: '14.jpg',
          value: 'O',
        },
        {
          label: this.$t('教育业'),
          imgUrl: '15.jpg',
          value: 'P',
        },
        {
          label: this.$t('医疗保健及社会工作服务业'),
          imgUrl: '16.jpg',
          value: 'Q',
        },
        {
          label: this.$t('艺术、娱乐及休闲服务业'),
          imgUrl: '17.jpg',
          value: 'R',
        },
        {
          label: this.$t('其他服务业'),
          imgUrl: '18.jpg',
          value: 'S',
        },
      ]
    },
  },
  methods: {
    linkCategory(val) {
      this.$router.push({
        name: 'Search',
        params: {
          cate: '/' + val + '/',
        },
      })
    },
  },
  components: {
    Body,
  },
}
</script>

<style lang="scss" scoped>
.index-main-description {
  & > div:first-of-type {
    height: 3.1875rem;
    display: flex;
    flex-flow: row;
    align-items: center;
    color: #666666;
    margin-top: 16px;
    font-size: 18px;
    justify-content: center;
  }
  & > div:last-of-type {
    color: #979797;
    font-size: 12px;
    line-height: 24px;
  }
}
.index-main-industry {
  position: relative;
  width: 8.0625rem;
  height: 2rem;
  margin: 30px auto;
  font-size: 1.5rem;
  font-weight: 600;
  color: #333333;
  line-height: 2rem;
  text-align: center;

  &::after {
    position: absolute;
    left: 50%;
    bottom: -18px;
    width: 2rem;
    height: 0.25rem;
    margin-left: -1rem;
    background: #333333;
    content: '';
  }
}
.main-category {
  display: flex;
  flex-flow: row wrap;
  margin-right: -18px;

  &-item {
    box-sizing: border-box;
    width: 17.75rem;
    height: 8.875rem;
    border-radius: 0.25rem;
    margin-top: 32px;
    margin-right: 18px;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:nth-child(4) {
      margin-right: 0rem;
    }
    &:hover {
      transform: translateY(-12px);
      box-shadow: 0px 5px 11px 0px rgba(74, 141, 253, 0.12);
      opacity: 0.8;
    }
    img {
      width: 284px;
      height: 142px;
      border-radius: 0.25rem;
    }
  }
  &-text {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    height: 5.375rem;
    top: 3.5rem;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    background: red;
    z-index: 1;
    padding-left: 0.75rem;
    display: flex;
    flex-flow: row;
    align-items: flex-end;
    padding-bottom: 0.8125rem;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
    color: #ffffff;
  }
}
</style>
