<!--
 * @Author: wyq
 * @Date: 2021-05-17 14:57:08
 * @LastEditTime: 2021-06-16 18:03:26
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \frontEnd\src\components\body\body.vue
-->
<template>
  <!-- 搜索 -->
  <div class="index-mian-search">
    <div class="search-mian">
      <el-cascader
        :key="index"
        :value="path"
        :options="options"
        :props="props"
        separator="－"
        filterable
        @change="change"
        :placeholder="$t('请选择行业分类')"
      ></el-cascader>
      <el-button type="primary" @click="searchIndex">
        <i class="el-icon-right"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valueSearch: '',
      path: '',
      options: [],
      index: 0,
    }
  },
  computed: {
    props() {
      // 强制重新渲染
      this.index++
      // 修改props配置
      return {
        expandTrigger: 'hover',
        value: 'industry_code',
        label:
          this.$i18n.locale == 'zh-CN'
            ? 'simplified_industry_name'
            : 'industry_name',
        children: 'child',
        checkStrictly: true,
      }
    },
  },
  methods: {
    searchIndex() {
      if (this.path.length) {
        this.$router.push({
          name: 'Search',
          params: {
            cate: '/' + this.path.join('/') + '/',
          },
        })
      } else {
        if (this.message) {
          this.message.close()
        }
        this.message = this.$message({
          message: this.$t('请选择行业分类'),
          type: 'warning',
        })
      }
    },
    getIndustryTree() {
      this.$service.getIndustryTree().then((res) => {
        this.options = res
      })
    },
    change(val) {
      this.path = val
    },
  },
  mounted() {
    this.getIndustryTree()
  },
}
</script>

<style lang="scss" scoped>
.index-mian-search {
  display: flex;
  flex-flow: row;
  justify-content: center;
  height: 25rem;
  background-image: linear-gradient(to right, #3e68ff, #31b3f9);
}

.search-mian {
  display: flex;
  flex-flow: row nowrap;
  width: 75rem;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/images/index/bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;

  /* 样式穿透 */
  ::v-deep .el-cascader {
    width: 800px;
    height: 54px;
    .el-input__inner {
      height: 54px;
      border-radius: 0;
    }
  }
  .el-button {
    height: 54px;
    border-radius: 0;
  }
}
</style>
